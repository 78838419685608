<template>
    <div v-if="!loading">
        <page-header>Коммерческие предложения</page-header>
        :)
    </div>
    <preloader v-else></preloader>
</template>

<script>
import AuthService from '../services/AuthService';

export default {
    data() {
        return {
            userData: null,
            userServices: null,
            serviceAccess: false,
        }
    },
    AuthService: null,
    created() {
        this.AuthService = new AuthService();
    },
    mounted() {
        this.AuthService.checkAuth(this);
    },
    computed: {
        loading() {
            if (this.userData && this.userServices && this.serviceAccess) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>