import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/Main.vue';
import Offers from '../views/Offers.vue';
import Settings from '../views/Settings.vue';
import Templates from '../views/Templates.vue';
import CreatingTemplate from '../views/CreatingTemplate.vue';
import UpdatingTemplate from '../views/UpdatingTemplate.vue';
import OfferEdit from '../views/OfferEdit.vue';
import View from '../views/View.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: Main,
    meta: {title: 'Главная'}
  },
  {
    path: '/offers',
    name: 'Коммерческие предложения',
    component: Offers,
    meta: {title: 'Коммерческие предложения'}
  },
  {
    path: '/settings',
    name: 'Настройки',
    component: Settings,
    meta: {title: 'Настройки'}
  },
  {
    path: '/sections',
    name: 'Шаблоны для КП',
    component: Templates,
    meta: {title: 'Шаблоны для КП'}
  },
  {
    path: '/sections/create',
    name: 'Создание шаблона',
    component: CreatingTemplate,
    meta: {title: 'Создание шаблона'}
  },
  {
    path: '/sections/:id',
    name: 'Редактирование шаблона',
    component: UpdatingTemplate,
    meta: {title: 'Редактирование шаблона'}
  },
  {
    path: '/offers/:id',
    component: OfferEdit,
    meta: {title: 'Редактирование КП'}
  },
  {
    path: '/view/:hash',
    name: 'Коммерческое предложение',
    component: View,
    meta: {title: 'Коммерческое предложение Starta'}
  },
  {
    path: '/not_found',
    component: NotFound,
    meta: {title: 'Страница не найдена'}
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/not_found',
    meta: {title: 'Страница не найдена'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
