<template>
  <Toast />
  <ScrollTop />

  <div v-if="!loading">

    <div class="cross_elements">
      <div class="row">
        <div class="cross_elements_left">
          <p>Помогаем брендам расти и меняться</p>
        </div>
        <div class="cross_elements_right">
          <a href="https://starta.ru" target="_blank">www.starta.ru</a>
          <div class="circle"></div>
        </div>
      </div>
    </div>

    <section class="main">
      <div class="row">
        <div class="main_bg">
          <div class="main_bg_circles">
            <div class="main_bg_circles_inner">
              <div class="main_bg_circles_inner_elem"></div>
            </div>
            <div class="main_bg_circles_outer">
              <div class="main_bg_circles_outer_elem"></div>
            </div>
          </div>
        </div>
        <div class="main_slogan">
          <p>
            <span>AGENCY OF</span> агентство<br />
            <span>MARKETING & VISUAL</span> маркетинговых и визуальных<br />
            <span>COMMUNICATION</span> коммуникаций
          </p>
        </div>
        <div class="main_logo"></div>
        <div class="main_grunge_circle"></div>
      </div>
    </section>

    <section class="kp_title">
      <div class="row" style="min-height: 70vh;">
        <div class="kp_title_bg">
          <div class="kp_title_bg_figure"></div>
          <div class="kp_title_bg_human"></div>
          <div class="kp_title_bg_arrows">
            <div class="kp_title_bg_arrows_item"></div>
            <div class="kp_title_bg_arrows_item"></div>
          </div>
        </div>
        <div class="kp_title_h1">
            <h1>
                <b>Коммерческое предложение</b>
                <!-- <p class="kp_title_h1_title">{{offerData.title}}</p> -->
                <p class="kp_title_h1_client" v-html="offerData.client"></p>
            </h1>
          
        </div>
      </div>
    </section>

    <!-- <section class="kp_slide" v-if="showBullets">
      <div class="row" style="min-height: 90vh;">
        <div class="kp_slide_container" style="overflow: hidden;">
          <div class="kp_slide_bullets">
            <div class="kp_slide_bullets_item">
              <p class="counter">7</p>
              <p class="text">лет на рынке</p>
              <p class="shadow">7</p>
            </div>
            <div class="kp_slide_bullets_item">
              <p class="counter">23</p>
              <p class="text">специалиста <br>в команде</p>
              <p class="shadow">23</p>
            </div>
            <div class="kp_slide_bullets_item">
              <p class="counter">300+</p>
              <p class="text">реализованных проектов</p>
              <p class="shadow">300+</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->



    <section class="kp_slide">
      <div class="row">
        <div class="kp_slide_bg">
          <div class="kp_slide_bg_big_circle"></div>
          <div class="kp_slide_bg_donut"></div>
          <div class="kp_slide_bg_donut_hhalf"></div>
        </div>

        <div class="kp_slide_container" v-for="section in sortSections(offerData.sections)" :key="section.id">
          <p class="slide_title">{{section.header}}</p>
          <div class="slide_fields">
            <div class="slide_fields_item">
              <!-- <p class="slide_subtitle">Заголовок поля</p> -->
              <div class="slide_value" v-html="section.content"></div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="kp_slide" v-if="showTeam">
      <div class="row" style="min-height: auto;">
        <div class="kp_slide_container">
          <p class="slide_title">Команда вашего проекта</p>
          <div class="kp_slide_team">
            <div class="kp_slide_team_item" v-for="item in offerData.team" :key="item.id">
              <p class="position">{{item.position}}</p>
              <p class="description">{{item.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="kp_slide" v-if="showAchievements">
      <div class="row" style="min-height: auto;">
        <div class="kp_slide_container">
          <p class="slide_title">Наши достижения</p>
          <div class="kp_slide_achievements">
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_01.png">
              </div>
              <p class="text">Топ-90 коммуникационных компаний России</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_02.png">
              </div>
              <p class="text">Топ-1 в категории комплексных диджитал агентств в Красноярске</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_02.png">
              </div>
              <p class="text">Топ-1 в оказании услуг SMM (маркетинг в соцсетях) в Красноярске</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_02.png">
              </div>
              <p class="text">Топ-1 в рейтинге диджитал-подрядчиков госструктур в Красноярске</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_02.png">
              </div>
              <p class="text">Топ-2 в категории "Разработка и продвижение сайтов" в Красноярске</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_03.png">
              </div>
              <p class="text">Сертифицированный партнёр <br>Яндекс</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_04.png">
              </div>
              <p class="text">46 место в рейтинге известности SMM-компаний 2022</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_05.png">
              </div>
              <p class="text">Дважды лауреаты номинации "Сайт дня" на международном конкурсе CSS Design Awards</p>
            </div>
            <div class="kp_slide_achievements_item">
              <div class="icon">
                <img src="@/assets/img/new_ach_06.png">
              </div>
              <p class="text">Золотой партнёр 1С-Битрикс</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="kp_slide" v-if="showPartners">
      <div class="row" style="min-height: auto;">
        <div class="kp_slide_container">
          <p class="slide_title">Наши клиенты</p>
          <div class="kp_slide_partners">
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_01.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_02.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_03.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_04.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_05.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_06.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_07.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_08.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_09.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_10.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_11.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_12.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_13.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_14.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_15.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_16.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_17.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_18.png">
              </div>
            </div>
            <div class="kp_slide_partners_item">
              <div class="logo">
                <img src="@/assets/img/logo_19.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="kp_slide">
      <div class="row" style="min-height: auto;">
        <div class="kp_slide_container kp_slide_container_total">
          <div class="kp_slide_container_total_controls">
              <h2>Возникли вопросы?</h2>
            <a nohref class="breif" @click="showCalbackModal = true">Обратный звонок</a>
            <p v-if="offerData.phone">или позвоните нам
              <a :href="'tel:' + offerData.phone" class="phone">{{offerData.phone}}</a>
            </p>
            <p v-if="offerData.email" style="font-size: 22px">
                <a :href="'mailto:' + offerData.email">{{offerData.email}}</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div class="row"></div>
    </footer>

    <Dialog v-model:visible="showCalbackModal" :style="{width: '450px'}" header="Обратный звонок" :modal="true" class="p-fluid">
        <div class="field">
            <label for="name">Ваше имя</label>
            <InputText v-model.trim="callbackData.name" class="p-inputtext-lg"  id="name" name="name" required="true" placeholder="Как к вам обращаться?"/>
        </div>
        <div class="field">
            <label for="phone">Телефон</label>
            <InputMask v-model.trim="callbackData.phone" class="p-inputtext-lg"  id="phone" name="phone" required="true" placeholder="+7 (___) ___-____" mask="+7 (999) 999-9999"/>
        </div>

        <template #footer>
            <Button label="Отправить" icon="pi pi-send" class="p-button-success p-button-lg" @click="sendCallbackForm" />
        </template>

    </Dialog>

  </div>
  <preloader v-else></preloader>
</template>

<script>
import OfferService from "../services/OfferService";

export default {
  data() {
    return {
      hash: this.$route.params.hash,
      kp_title: null,
      offerData: null,
      loading: true,
      showCalbackModal: false,
      callbackData: [],
      showBullets: false,
      showAchievements: false,
      showPartners: false,
      showTeam: false,
    };
  },
  OfferService: null,
  methods: {
    getOfferData(hash) {
      const that = this;
      this.OfferService.getOfferDataByHash(hash).then((data) => {
        if (data.status) {
          that.offerData = data.message;
          that.offerData.blocks = JSON.parse(data.message.blocks);
          that.offerData.team = JSON.parse(data.message.team);

          if (that.offerData.blocks.includes('bullets')) {
            that.showBullets = true;
          }
          if (that.offerData.blocks.includes('achievements')) {
            that.showAchievements = true;
          }
          if (that.offerData.blocks.includes('partners')) {
            that.showPartners = true;
          }
          if (that.offerData.blocks.includes('team')) {
            that.showTeam = true;
          }

          that.loading = false;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
          that.$router.push("/not_found");
        }
      });
    },
    sortSections(array) {
        let newArray = array.sort((a,b) => a.sortIndex - b.sortIndex);
        return newArray;
    },
    sendCallbackForm() {
        const that = this;
        if (this.callbackData.name && this.callbackData.phone) {
            const formData = new FormData();
            formData.append('action', 'sendCallback');
            formData.append('name', this.callbackData.name);
            formData.append('phone', this.callbackData.phone);
            formData.append('hash', this.hash);

            this.OfferService.sendCallback(formData).then(data => {
                if (data.status) {
                    that.$toast.add({
                        severity: "success",
                        summary: 'Спасибо!',
                        detail: 'Мы уже получили вашу заявку и свяжемся в ближайшее время',
                        life: 4000,
                    });
                    this.showCalbackModal = false;
                } else {
                    that.$toast.add({
                        severity: "error",
                        summary: 'Возникла ошибка',
                        detail: data.message,
                        life: 4000,
                    });
                }
            })
        } else {
          that.$toast.add({
            severity: "warn",
            detail: 'Необходимо заполнить оба поля',
            life: 3000,
          });
        }
    }
  },
  created() {
    this.OfferService = new OfferService();
  },
  mounted() {
    this.getOfferData(this.hash);
  },
};
</script>

<style lang="scss" scoped>
@import url(~@/assets/css/app.css);

.row {
  max-width: 80rem;
}

.cross_elements_left {
  left: -20px;
}
.cross_elements_right {
  left: calc(100% - 0px);
}

.kp_title_h1 {
    color: rgb(129, 129, 129);
    &_title {
        font-size: 26px;
        margin-bottom: 5px;
        color: rgb(73, 80, 87);
    }
    &_client {
        font-size: 24px;
        font-weight: 400;
        // color: rgb(73, 80, 87);
        color: black;
        margin-top: 7px;
        line-height: 1.2;
    }
    h1 {
      max-width: 430px;
      b {
        font-weight: 500;
        margin: 0 auto;
      }
    }
}
.slide_title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}
.kp_slide_container {
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: 480px) {
      padding-left: 25px;
      padding-right: 25px;
    }
}
.kp_slide_bullets {
  position: relative;
  &_item {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    max-width: 390px;
    p {
      font-family: 'Raleway', 'Arial', sans;
      &.counter {
        font-size: 110px;
        font-weight: 500;
        position: relative;
        flex: 0 0 100%;
        margin-bottom: 0;
        line-height: 1.1;
        &:after {
          position: absolute;
          content: '';
          width: 60px;
          height: 60px;
          display: table;
          background: url('../assets/img/title_bg_arrow.svg') no-repeat center;
          background-size: contain;
          top: 50%;
          transform: rotate(180deg) translateY(45%);
          left: calc(100% - 110px);
          opacity: .5;
        }
      }
      &.text {
        font-size: 32px;
        font-weight: 200;
      }
      &.shadow {
        font-size: 174px;
        font-weight: 800;
        opacity: .05;
        position: absolute;
        display: table;
        line-height: 1;
        margin: 0;
        left: 100%;
        top: 50%;
        transform: translateY(-59%);
        white-space: nowrap;
      }
    }
    &:nth-of-type(1) {
      top: 4vh;
      left: 23vw;
    }
    &:nth-of-type(2) {
      top: 29vh;
      left: 6vw;
    }
    &:nth-of-type(3) {
      top: 58vh;
      left: 16.4vw;
    }
  }
}
.kp_slide_achievements {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 15px;
  &_item {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 50px;
    .icon {
      flex: 0 0 90px;
      max-width: 90px;
      width: 90px;
    }
    .text {
      padding-left: 26px;
      font-size: 18px;
      font-weight: 600;
      font-family: 'Raleway', 'Arial', sans;
    }
  }
}
.kp_slide_partners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  &_item {
    .logo {
      max-width: 120px;
      display: table;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
.kp_slide_team {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  &_item {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-bottom: 40px;
    padding-right: 50px;
    p {
      max-width: 350px;
      &.position {
        font-size: 20px;
        font-weight: 600;
        font-family: 'Raleway', 'Arial', sans;
        margin-bottom: 10px;
        line-height: 1.2;
      }
      &.description {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .cross_elements_left {
    left: 0px;
  }
  .cross_elements_right {
    left: calc(100% - 20px);
  }
}

@media screen and (max-width: 700px) {
  .kp_slide_achievements {
    &_item {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  .kp_slide_bullets {
    &_item {
      position: relative;
      top: initial !important;
      left: initial !important;
      margin-bottom: 20px;
      p {
        &.counter {
          font-size: 85px;
          &:after {display: none;}
        }
        &.text {
          font-size: 28px;
        }
        &.shadow {display: none;}
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .kp_slide_team {
    &_item {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .kp_slide_achievements {
    &_item {
      margin-bottom: 45px;
      flex-wrap: wrap;
      .icon, .text {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
      .icon {
        img {
          max-width: 90px;
          margin: 0 auto 10px auto;
          display: table;
        }
      }
      .text {
        text-align: center;
        padding: 0;
      }
    }
  }
}

</style>