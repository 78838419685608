<template>
	<div class="layout-footer">

	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>