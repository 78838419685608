export default class ProductService {

	getOfferData(id) {
		const formData = new FormData();
		formData.append('action', 'getOfferData');
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	getOfferDataByHash(hash) {
		const formData = new FormData();
		formData.append('action', 'getOfferDataByHash');
		formData.append('hash', hash);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				//console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	updateOffer(formData) {
		// const formData = new FormData();
		// formData.append('action', 'getOfferData');
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Получение списка и данных вообще всех КП
	getAllOffers() {
		const formData = new FormData();
		formData.append('action', 'getOffers');
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Получение списка и данных КП по ID пользователя
	getOffersByID(id) {
		const formData = new FormData();
		formData.append('action', 'getOffersByID');
		// formData.append('token', localStorage.starta_token);
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Получение списка всех шаблонов блоков
	getTemplatesList() {
		const formData = new FormData();
		formData.append('action', 'getTemplatesList');
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Получение данных шаблона по ID
	getTemplateData(id) {
		const formData = new FormData();
		formData.append('action', 'getTemplateData');
		formData.append('id', id);
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Создание шаблона
	createTemplate(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// Обновление шаблона
	updateTemplate(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// обновление превью шаблона
	uploadTemplatePreview(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	getManagers() {
		const formData = new FormData();
		formData.append('action', 'getManagers');
		// formData.append('token', localStorage.starta_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	createOffer(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	generateHash(formData) {

		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	deleteOffer(id) {
		const formData = new FormData();
		formData.append('action', 'deleteOffer');
		formData.append('id', id);
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	deleteTemplate(id) {
		const formData = new FormData();
		formData.append('action', 'deleteTemplate');
		formData.append('id', id);
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})		
	}

	sendCallback(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/offers.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

}