<template>
  <page-header>Новый шаблон</page-header>
  <div class="col-12" v-if="serviceAccess">
    <div class="card" v-if="!loading">
      <edit-template-form :data="template" :newTpl="true" @save="createTemplate($event)" v-if="userData.group == 'admin'"></edit-template-form>
    </div>
    <preloader v-else></preloader>
  </div>
  <preloader v-else></preloader>
</template>

<script>
import AuthService from "../services/AuthService";
import OfferService from "../services/OfferService";
import EditTemplateForm from "../components/ui/EditTemplateForm.vue";

export default {
  data() {
    return {
      userData: [],
      userServices: null,
      serviceAccess: false,
      template: [],
    };
  },
  OfferService: null,
  AuthService: null,
  components: {
    "edit-template-form": EditTemplateForm,
  },
  created() {
    this.AuthService = new AuthService();
    this.OfferService = new OfferService();
  },
  mounted() {
    this.AuthService.checkAuth(this);
  },
  computed: {
    loading() {
      if (this.userData && this.userServices) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    createTemplate(newTplData) {
      const that = this;
      const formData = new FormData();

      formData.append("action", "createTemplate");
      formData.append("name", newTplData.name);
      formData.append("header", newTplData.header);
      formData.append("description", newTplData.description);
      formData.append("image", newTplData.image);
      formData.append("content", newTplData.content);

      if (newTplData.name && newTplData.header && newTplData.content) {
        this.OfferService.createTemplate(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 4000,
            });
            setTimeout(function(){
                that.$router.push('/sections');
            }, 1000);
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          summary: "Не все поля заполнены",
          detail:
            "Необходимо заполнить: название, заголовок и содержимое шаблона",
          life: 4000,
        });
      }
    },
  },
};
</script>