<template>
    <DataTable :value="data" responsiveLayout="scroll" :paginator="true" :rows="20" sortField="id" :sortOrder="-1" >
        <template #empty>
            Пока не создано ни одного коммерческого предложения
        </template>
        <Column field="id" header="ID" :sortable="true"></Column>
        <Column field="title" header="Название" :sortable="true">
            <template #body="slotProps">
                <router-link :to="'/offers/' + slotProps.data.id">{{slotProps.data.title}}</router-link>
            </template>
        </Column>
        <Column field="client" header="Компания" :sortable="true"></Column>
        <Column field="manager.name" header="Менеджер" :sortable="true"></Column>
        <Column field="status" header="Статус" :sortable="true">
            <template #body="slotProps">
                {{formatOfferStatus(slotProps.data.status)}}
            </template>
        </Column>
        <!-- <Column field="created" header="Создано" :sortable="true"></Column> -->
        <Column field="modified" header="Последнее изменение" :sortable="true"></Column>
        <Column style="min-width: 200px">
            <template #body="slotProps">
                <router-link :to="'/offers/' + slotProps.data.id">
                    <Button icon="pi pi-pencil" class="p-button-sm p-button-secondary mr-2"/>
                </router-link>
                <Button icon="pi pi-eye" class="p-button-sm p-button-info mr-2" v-if="slotProps.data.hash" @click="viewOffer(slotProps.data.hash)"/>
                <Button icon="pi pi-trash" class="p-button-sm p-button-danger" @click="confirmDeleteOffer(slotProps.data)" />
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="deleteOfferDialog" :style="{width: '450px'}" header="Подтвердите удаление" :modal="true">
        <div class="confirmation-content">
            
            <span v-if="offerToDelete"><i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem; float: left;" />Вы уверены, что хотите удалить коммерческое предложение "{{offerToDelete.title}}"?</span>
        </div>
        <template #footer>
            <Button label="Нет" icon="pi pi-times" class="p-button-text" @click="deleteOfferDialog = false"/>
            <Button label="Да, удалить" icon="pi pi-check" class="p-button-warning" @click="deleteOffer(offerToDelete.id)" />
        </template>
    </Dialog>
</template>

<script>
import OfferService from '../../services/OfferService';
export default {
    data() {
        return {
            deleteOfferDialog: false,
            offerToDelete: null,
        }
    },
    props: ['data'],
    emits: ['refreshOffers'],
    OfferService: null,
    created() {
        this.OfferService = new OfferService();
    },
    methods: {
        formatOfferStatus(value) {
            if (value) {
                return 'Активно';
            } else {
                return 'Неактивно';
            }
        },
        confirmDeleteOffer(offer) {
            this.offerToDelete = offer;
            this.deleteOfferDialog = true;
        },
        deleteOffer(id) {
            const that = this;
            this.OfferService.deleteOffer(id).then(data => {
                if (data.status) {
                    this.deleteOfferDialog = false;
                    this.$emit('refreshOffers');
                    that.$toast.add({
                        severity: "success",
                        detail: data.message,
                        life: 4000,
                    });
                } else {
                    that.$toast.add({
                        severity: "error",
                        detail: data.message,
                        life: 4000,
                    });
                }
            });
        },
        viewOffer(hash) {
            let offer_link = '/view/' + hash;
            window.open(offer_link, '_blank');
        }
    }
}
</script>