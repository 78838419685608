<template>
  <page-header>Шаблоны для КП</page-header>
  <div class="col-12" v-if="serviceAccess">
    <div class="card" v-if="!loading">
      <Toolbar class="mb-4" v-if="userData.group == 'admin'">
        <template #start>
          <router-link to="/sections/create">
            <Button label="Добавить шаблон" icon="pi pi-plus" class="p-button-success mr-2" />
          </router-link>
        </template>
      </Toolbar>
      <templates-list :data="templates" @refreshTemplates="getTemplatesList" v-if="userData.group == 'admin'"></templates-list>
    </div>
    <preloader v-else></preloader>
  </div>
  <preloader v-else></preloader>
</template>

<script>
import AuthService from "../services/AuthService";
import OfferService from "../services/OfferService";

export default {
  data() {
    return {
      templates: [],
      userData: [],
      userServices: null,
      serviceAccess: false,
    };
  },
  OfferService: null,
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
    this.OfferService = new OfferService();
  },
  mounted() {
    this.AuthService.checkAuth(this);
    this.getTemplatesList();
  },
  computed: {
    loading() {
      if (this.userData && this.userServices && this.templates) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getTemplatesList() {
      const that = this;
      this.OfferService.getTemplatesList().then((data) => {
        if (data.status) {
          that.templates = data.message;
          that.loading = false;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
  },
};
</script>