<template>
  <page-header>Редактирование шаблона</page-header>
  <div class="col-12" v-if="serviceAccess">
    <div class="card" v-if="!loading">
      <edit-template-form :data="template" :newTpl="false" @save="updateTemplate($event)" v-if="userData.group == 'admin' && template"></edit-template-form>
    </div>
    <preloader v-else></preloader>
  </div>
  <preloader v-else></preloader>
</template>

<script>
import AuthService from "../services/AuthService";
import OfferService from "../services/OfferService";
import EditTemplateForm from "../components/ui/EditTemplateForm.vue";

export default {
  data() {
    return {
      userData: [],
      userServices: null,
      serviceAccess: false,
      template: null,
      templateId: this.$route.params.id,
    };
  },
  OfferService: null,
  AuthService: null,
  components: {
    "edit-template-form": EditTemplateForm,
  },
  created() {
    this.AuthService = new AuthService();
    this.OfferService = new OfferService();

    
  },
  mounted() {
    this.AuthService.checkAuth(this);
    this.getTemplateData(this.templateId);
  },
  computed: {
    loading() {
      if (this.userData && this.userServices && this.template) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getTemplateData(id) {
      const that = this;
      this.OfferService.getTemplateData(id).then((data) => {
        if (data.status) {
          that.template = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    updateTemplate(tplData) {
      const that = this;
      const formData = new FormData();

      formData.append("action", "updateTemplate");
      formData.append("id", tplData.id);
      formData.append("name", tplData.name);
      formData.append("header", tplData.header);
      formData.append("description", tplData.description);
      formData.append("image", tplData.image);
      formData.append("content", tplData.content);

      if (tplData.id && tplData.name && tplData.header && tplData.content) {
        this.OfferService.createTemplate(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 4000,
            });
            setTimeout(function(){
                that.$router.push('/sections');
            }, 1000);
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          summary: "Не все поля заполнены",
          detail:
            "Необходимо заполнить: название, заголовок и содержимое шаблона",
          life: 4000,
        });
      }
    },
  },
};
</script>