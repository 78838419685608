<template>
  <page-header v-if="!loading && serviceAccess">{{offerData.title}}</page-header>
  <div class="grid col-12" v-if="serviceAccess">
    <div class="col-12">
      <form class="card" @submit.prevent="updateOffer" id="offerUpdateForm" v-if="!loading">
          <edit-offer-toolbar 
            :offer="offerData" 
            @publicate="publicateOffer()" 
            @view="viewOffer()" 
            @changeStatus="changeStatus($event)"
            v-if="offerData"
        ></edit-offer-toolbar>
        <div class="grid mb-4">
          <div class="col-6">
            <Panel :toggleable="true" class="mb-3">
              <template #header>
                <div class="section_header">
                  <p class="title">Основные настройки</p>
                </div>
              </template>
              <div class="grid" style="justify-content: space-around;">
                <div class="grid col-12">
                  <div class="col-6">
                    <label for="offer_title">
                      <span>Название КП</span>
                    </label>
                    <InputText
                      type="text"
                      id="offer_title"
                      name="title"
                      v-model="offerData.title"
                      required
                    />
                  </div>
                  <div class="col-6">
                    <label for="offer_client">
                      <span>Название компании</span>
                    </label>
                    <InputText
                      type="text"
                      id="offer_client"
                      name="client"
                      v-model="offerData.client"
                      required
                    />
                  </div>
                </div>
                <div class="grid col-12">
                  <div class="col-6">
                    <label for="offer_manager">
                      <span>Менеджер</span>
                    </label>
                    <Dropdown
                      v-model="offerData.manager_id"
                      id="offer_manager"
                      name="manager_id"
                      :options="managers"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Выберите менеджера"
                      :class="'w-full ' + disabledForUsers"
                    />
                  </div>
                  <div class="col-6">
                    <label for="offer_contact_phone">
                      <span>Телефон</span>
                    </label>
                    <InputMask
                      mask="8 999 999-99-99"
                      id="offer_contact_phone"
                      name="phone"
                      type="text"
                      v-model="offerData.phone"
                      placeholder="8 ___ ___-__-__"
                      class="w-full"
                      required
                    />
                  </div>
                  <div class="col-6">
                    <label for="offer_contact_email">
                      <span>E-mail</span>
                    </label>
                    <InputText
                      type="email"
                      id="offer_contact_email"
                      name="email"
                      v-model="offerData.email"
                      class="w-full"
                      required
                    />
                  </div>
                </div>
                <div class="grid col-12">
                    <label class="col-12 pb-0">Дополнительные блоки</label>
                    <div class="col-3">
                        <div class="field-checkbox">
                            <Checkbox id="block_bullets" name="blocks" value="bullets" v-model="offerData.blocks" />
                            <label for="block_bullets">Буллиты</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="field-checkbox">
                            <Checkbox id="block_achievements" name="blocks" value="achievements" v-model="offerData.blocks" />
                            <label for="block_achievements">Достижения</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="field-checkbox">
                            <Checkbox id="block_partners" name="blocks" value="partners" v-model="offerData.blocks" />
                            <label for="block_partners">Партнеры</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="field-checkbox">
                            <Checkbox id="block_team" name="blocks" value="team" v-model="offerData.blocks" />
                            <label for="block_team">Команда</label>
                        </div>
                    </div>
                </div>
                <div class="grid col-12" v-if="teamListEnabled">
                    <label class="col-12 pb-0">Состав команды</label>
                    <div class="col-6">
                      <MultiSelect v-model="offerData.team" class="w-full" :options="teamList" optionLabel="position" placeholder="Выберите участников проекта" />
                    </div>
                </div>
              </div>
            </Panel>

            <div class="control-buttons grid col-12 mb-4">
            <Button
              label="Добавить пустой блок"
              @click="addSection('default', 0)"
              icon="pi pi-plus"
              class="mr-2"
            ></Button>
            <Button
              type="button"
              icon="pi pi-th-large"
              label="Выбрать блок из шаблонов"
              @click="addSectionTemplate($event, 0)"
              aria:haspopup="true"
              class="p-button-help"
              aria-controls="overlay_panel"
            />
            </div>
          </div>
          <div class="col-3">
            <Panel :toggleable="true" class="mb-3">
              <template #header>
                <div class="section_header">
                  <p class="title">Структура КП</p>
                </div>
              </template>
              <div class="grid offer_structure">
                <div class="col-12">
                  <div
                    v-for="(section, ind) of offerData.sections"
                    :key="section.id"
                    class="offer_structure_container"
                  >
                    <div class="offer_structure_section" :data-section_id="section.id">
                      <p class="mb-1 pt-2 pb-2 pl-2 pr-1" @click="scrollTo($event)">{{section.header}}</p>
                      <div class="offer_structure_section_controls">
                        <button :class="'p-panel-header-icon p-link mr-2' + (ind == 0 ? ' p-disabled' : '')" @click.prevent="sort($event, offerData.sections,ind, 'up')">
                          <span class="pi pi-angle-up"></span>
                        </button>
                        <!-- <span class="mr-2">#{{ind}}</span> -->
                        <button :class="'p-panel-header-icon p-link mr-2' + (ind >= (Number(offerData.sections.length) - 1) ? ' p-disabled' : '')" @click.prevent="sort($event, offerData.sections,ind, 'down')">
                          <span class="pi pi-angle-down"></span>
                        </button>
                        <button class="p-panel-header-icon p-link mr-2" @click.prevent="removeSection">
                          <span class="pi pi-trash"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
          <div class="col-3">
            <Panel :toggleable="true" class="mb-3">
              <template #header>
                <div class="section_header">
                  <p class="title">Статистика</p>
                </div>
              </template>
              <div class="grid offer_statistic">
                <div class="col-12">
                  <ul>
                    <li>
                      <span>Создано: </span>
                      <span>{{offerData.created}}</span>
                    </li>
                    <li>
                      <span>Изменено: </span>
                      <span>{{offerData.modified}}</span>
                    </li>
                    <li>
                      <span>Всего просмотров: </span>
                      <span>1</span>
                    </li>
                    <li>
                      <span>Переходов на сайт: </span>
                      <span>2</span>
                    </li>
                    <li>
                      <span>Кликов по email: </span>
                      <span>0</span>
                    </li>
                    <li>
                      <span>Кликов по номеру телефона: </span>
                      <span>1</span>
                    </li>
                    <li>
                      <span>Отправлено форм:</span>
                      <span>1</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Panel>
          </div>
        </div>
        <div
          v-for="(section, ind) of offerData.sections"
          :key="section.id"
          class="mb-4 offer_section_container"
        >
          <Panel :toggleable="true" class="mb-3 offer_section" :data-section_id="section.id">
            <template #header>
              <div class="section_header">
                <p class="title">{{section.header}}</p>
                <div class="editing">
                  <Inplace :closable="true" @open="hideSectionTitle" @close="showSectionTitle">
                    <template #display>
                      <span class="pi pi-pencil" style="vertical-align: middle"></span>
                    </template>
                    <template #content>
                      <InputText v-model="section.header" autofocus />
                    </template>
                  </Inplace>
                </div>
              </div>
            </template>
            <!-- <template #icons>
              <button :class="'p-panel-header-icon p-link mr-2' + (ind == 0 ? ' p-disabled' : '')" @click.prevent="sort($event, offerData.sections,ind, 'up')">
                <span class="pi pi-angle-up"></span>
              </button>
              <span class="mr-2">#{{ind}}</span>
              <button :class="'p-panel-header-icon p-link mr-2' + (ind >= (Number(offerData.sections.length) - 1) ? ' p-disabled' : '')" @click.prevent="sort($event, offerData.sections,ind, 'down')">
                <span class="pi pi-angle-down"></span>
              </button>
              <button class="p-panel-header-icon p-link mr-2" @click.prevent="removeSection">
                <span class="pi pi-trash"></span>
              </button>
            </template> -->

            <input type="hidden" :name="'s_header_' + section.id" v-model="section.header" />
            <textarea
              :name="'s_content_' + section.id"
              v-model="section.content"
              style="display: none"
            ></textarea>
            <input type="hidden" :name="'s_sortindex_' + section.id" v-model="section.sortIndex" />
            <input type="hidden" :name="'s_template_' + section.id" v-model="section.template" />
            <input type="hidden" :name="'s_to_delete_' + section.id" value="0" />

            <editor
              api-key="eglpylzpl5vuggnchp04c2din4ratgqevieq9rpscgylsuky"
              :ref="'s_content_' + section.id + '_tiny'"
              v-model="section.content"
              :name="'s_content_' + section.id + '_tiny'"
              :init="{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table code help wordcount',
                                ],
                                browser_spellcheck: true,
                                language: 'ru',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help',
                            }"
            />
          </Panel>
            <div class="control-buttons grid col-12 mb-4">
                <Button
                label="Добавить пустой блок"
                @click="addSection('default', ind)"
                icon="pi pi-plus"
                class="mr-2"
                ></Button>
                <Button
                    type="button"
                    icon="pi pi-th-large"
                    label="Добавить блок из шаблонов"
                    @click="addSectionTemplate($event, ind)"
                    aria:haspopup="true"
                    class="p-button-help"
                    aria-controls="overlay_panel"
                />
            </div>
        </div>
        <!-- <SplitButton label="Добавить блок" @click="addSection('default')" icon="pi pi-plus" :model="sectionsTpls"></SplitButton> -->
        <Button type="submit" label="Сохранить КП"></Button>
      </form>

      <preloader v-else></preloader>
      <OverlayPanel
        ref="op"
        appendTo="body"
        :showCloseIcon="true"
        id="overlay_panel"
        style="width: 640px"
        :breakpoints="{'960px': '75vw'}"
      >
        <DataTable
          :value="templatesList"
          v-model:selection="selectedTemplate"
          selectionMode="single"
          :paginator="true"
          :rows="10"
          :class="'p-datatable-sm'"
          @rowSelect="onTemplateSelect($event, currentSection)"
          responsiveLayout="scroll"
        >
          <!-- <Column field="id" header="ID" sortable style="width: 10%"></Column> -->
          <Column field="header" header="Название" sortable style="width: 90%">
            <template #body="slotProps">
                <div class="template_name">
                    <Avatar :image="'https://offer.starta.eco/templates/' + slotProps.data.image"  class="mr-2" size="small" v-if="slotProps.data.image"/>
                    <Avatar image="https://offer.starta.eco/assets/img/tpl_nophoto.jpg"  class="mr-2" size="small"  v-else/>
                    <div>
                        <p class="h6 mb-0">{{slotProps.data.header}}</p>
                        <p class="mb-0 text-xs">{{slotProps.data.description}}</p>
                    </div>
                </div>
                
            </template>
            </Column>
        </DataTable>
      </OverlayPanel>
    </div>
  </div>
  <preloader v-else></preloader>
</template>

<script>
import AuthService from "../services/AuthService";
import OfferService from "../services/OfferService";
import Editor from "@tinymce/tinymce-vue";
import editOfferToolbar from '../components/ui/editOfferToolbar.vue';

export default {
  data() {
    return {
      userData: null,
      userServices: null,
      serviceAccess: false,
      managers: [],
      templatesList: [],
      selectedTemplate: null,
      currentSection: null,

      loading: true,
      offer_id: this.$route.params.id,
      offerData: [],
      sectionsTpls: [
        {
          label: "Пустой шаблон",
          icon: "pi pi-refresh",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: "Список кейсов",
          icon: "pi pi-times",
          command: () => {
            this.$toast.add({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
        {
          label: "Контакты",
          icon: "pi pi-external-link",
          command: () => {
            window.location.href = "https://vuejs.org/";
          },
        },
      ],

      teamList: [
        {
          id: '1',
          position: 'Проектный менеджер',
          description: 'Координация команды контроль выполнения задач по проекту'
        },
        {
          id: '2',
          position: 'Технический директор',
          description: 'Тестирование проекта и контроль качества'
        },
        {
          id: '3',
          position: 'Дизайнер',
          description: 'Проработка визуальной концепции и создание интерфейса'
        },
        {
          id: '4',
          position: 'Frontend-разработчик',
          description: 'Вёрстка и эффекты'
        },
        {
          id: '5',
          position: 'Backend-разработчик',
          description: 'Интеграция с CMS'
        },
        {
          id: '6',
          position: 'Копирайтер',
          description: 'Наполнение качественным контентом'
        },
        {
          id: '7',
          position: 'Аккаунт-менеджер',
          description: 'Коммуникация с клиентом, контроль и информирование о результатах этапов и проекта в целом'
        },
        {
          id: '8',
          position: 'SMM-специалист',
          description: 'Подготовка стратегии и ведение социальных сетей'
        },
        {
          id: '9',
          position: 'Таргетолог',
          description: 'Настройка и ведение рекламных кампаний в социальных сетях'
        },
        {
          id: '10',
          position: 'Директолог',
          description: 'Настройка и ведение рекламных кампаний в Яндекс.Директ'
        },
        {
          id: '11',
          position: 'SEO-специалист',
          description: 'Разработка стратегии и выполнение задач для продвижения сайтов в поисковых системах'
        },
      ],

    };
  },
  AuthService: null,
  OfferService: null,
  components: {
    editor: Editor,
    'edit-offer-toolbar': editOfferToolbar
  },
  computed: {
    offerAccess() {
      const that = this;
      if (this.userData.group == 'admin' || this.offerData.manager_id == this.userData.id) {
        return true;
      } else {
        that.$toast.add({
            severity: "warn",
            summary: "Недостаточно прав",
            detail: 'Редактировать это КП может только его автор',
            life: 4000,
          });
        return false;
      }
    },
    teamListEnabled() {
      if (this.offerData.blocks.includes("team")) {
        return true;
      } else {
        return false;
      }
    },
    formatedStatus() {
      if (this.offerData.status == 1) {
        return true;
      } else {
        return false;
      }
    },
    disabledForUsers() {
      if (this.userData.group === "user") {
        return " p-disabled ";
      } else {
        return " ";
      }
    },
  },
  created() {
    this.AuthService = new AuthService();
    this.OfferService = new OfferService();
  },
  mounted() {
    this.AuthService.checkAuth(this);
    this.getOfferData(this.offer_id);
    this.getManagers();
    this.getTemplatesList();
  },
  methods: {
    sort(e, arr, a, dir = 'up') {
      let b;
      if (dir === 'up') {
        b = a--;
      } else {
        b = a++;
      }
      arr[a] = arr.splice(b, 1, arr[a])[0];
      this.offerData.sections = arr;
      // let thisSection = e.target.closest('.offer_section_container');
      // setTimeout(function() {
      //   let coordY = thisSection.getBoundingClientRect().top + window.pageYOffset;
      //   window.scrollTo({top: coordY - 100, behavior: 'smooth'});
      // }, 0);
      let section_a_id = arr[a].id;
      let section_b_id = arr[b].id;
      let tinyItemA = this.$refs['s_content_' + section_a_id + '_tiny'][0];
      tinyItemA.rerender();
      let tinyItemB = this.$refs['s_content_' + section_b_id + '_tiny'][0];
      tinyItemB.rerender();      
    },
    scrollTo(e) {
      let thisSection = e.target.closest('.offer_structure_section');
      let section_id = thisSection.getAttribute('data-section_id');
      var offer_block = document.querySelector("#offerUpdateForm .offer_section[data-section_id='" + section_id + "']").closest('.offer_section_container');

      setTimeout(function() {
        let coordY = offer_block.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({top: coordY - 100, behavior: 'smooth'});
      }, 0);
    },
    addSectionTemplate(event, index) {
      this.currentSection = index;
      this.$refs.op.toggle(event);
    },
    onTemplateSelect(event, index) {
        this.addSection(event.data.name, index, event.data.id);
        this.$refs.op.hide();
    },
    getOfferData(id) {
      const that = this;
      this.OfferService.getOfferData(id).then((data) => {
        if (data.status) {
          that.offerData = data.message;
          that.offerData.blocks = JSON.parse(data.message.blocks);
          that.offerData.team = JSON.parse(data.message.team);
          that.loading = false;
          console.log(this.offerData);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    getTemplatesList() {
      const that = this;
      this.OfferService.getTemplatesList().then((data) => {
        if (data.status) {
          that.templatesList = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    getManagers() {
      const that = this;
      this.OfferService.getManagers().then((data) => {
        if (data.status) {
          that.managers = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    updateOffer() {
      const formData = new FormData();
      const that = this;

      formData.append("action", "updateOffer");
      formData.append("offer_id", this.offer_id);
      formData.append("title", this.offerData.title);
      formData.append("client", this.offerData.client);
      formData.append("status", this.offerData.status);
      formData.append("phone", this.offerData.phone);
      formData.append("email", this.offerData.email);
      formData.append("blocks", this.offerData.blocks);
      formData.append("team", JSON.stringify(this.offerData.team));

      let sections_elements = document.querySelectorAll(
        "#offerUpdateForm .offer_section"
      );
      let sections = [];

      // sections_elements.forEach(function(element, index) {
      //   console.log(element);
      //   console.log(index);
      // })

      //for (let element of sections_elements) {
      sections_elements.forEach(function(element, index) {
        let section_fields = {};
        let section_id = element.getAttribute("data-section_id");
        let section_header = document.querySelector(
          "#offerUpdateForm .offer_section [name=s_header_" + section_id + "]"
        );
        // let section_sortindex = document.querySelector(
        //   "#offerUpdateForm .offer_section [name=s_sortindex_" +
        //     section_id +
        //     "]"
        // );
        let section_content = document.querySelector(
          "#offerUpdateForm .offer_section [name=s_content_" + section_id + "]"
        );
        let section_template = document.querySelector(
          "#offerUpdateForm .offer_section [name=s_template_" + section_id + "]"
        );
        let section_to_delete = document.querySelector(
          "#offerUpdateForm .offer_section [name=s_to_delete_" +
            section_id +
            "]"
        );

        // if (section_id !== 'new_block') {
        //     section_id = Number(section_id);
        // }

        section_fields = {
          id: section_id,
          header: section_header.value,
          sortindex: Number(index),
          content: section_content.value,
          template: section_template.value,
          to_delete: section_to_delete.value,
        };
        sections.push(section_fields);
      })

      formData.append("sections", JSON.stringify(sections));

      this.OfferService.updateOffer(formData).then((data) => {
        if (data.status) {
          that.getOfferData(that.offer_id);
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    changeStatus(status) {
        this.offerData.status = status;
    },
    addSection(tpl, after = null, id = null) {
      const that = this;
      let id_new_block = "new_block_" + Math.floor(Math.random() * 9999999);
      let sortId = 0;
      if (after) {
          sortId = after + 1;
      } else {
          sortId = after;
      }
        if (id) {
            this.OfferService.getTemplateData(id).then(data => {
                if (data.status) {
                    that.offerData.sections.splice(sortId, 0, {
                        id: id_new_block,
                        sortIndex: sortId,
                        header: data.message.header,
                        content: data.message.content,
                        template: tpl,
                    });
                } else {
                    that.$toast.add({
                        severity: "error",
                        detail: data.message,
                        life: 4000,
                    });
                }
            })
        } else {
          that.offerData.sections.splice(sortId, 0, {
            id: id_new_block,
            sortIndex: sortId,
            header: "Новый блок",
            content: "",
            template: "default",
          });
        }
    },
    removeSection(e) {
      var panel = e.target.closest(".offer_structure_container");
      var section_id = panel.querySelector(".offer_structure_section").getAttribute("data-section_id");
      var offer_block = document.querySelector("#offerUpdateForm .offer_section[data-section_id='"+section_id+"']").closest('.offer_section_container');
      var offer_block_delete_input = offer_block.querySelector("[name=s_to_delete_" + section_id + "]");
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Вы уверены, что хотите удалить этот блок?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          // panel.remove();
          offer_block_delete_input.value = "1";
          panel.style.display = "none";
          offer_block.style.display = "none";
          that.$toast.add({
            severity: "info",
            detail: "Блок удалён. Не забудьте сохранить КП.",
            life: 4000,
          });
        },
      });
    },
    sortSections(array) {
      let newArray = array.sort((a, b) => a.sortIndex - b.sortIndex);
      return newArray;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    hideSectionTitle(e) {
      let title = e.target.closest(".section_header").querySelector(".title");
      title.style.display = "none";
    },
    showSectionTitle(e) {
      let title = e.target.closest(".section_header").querySelector(".title");
      title.style.display = "block";
    },
    publicateOffer() {
      const formData = new FormData();
      const that = this;

      formData.append("action", "generateHash");
      formData.append("id", this.offer_id);
      formData.append("title", this.offerData.title);
      formData.append("client", this.offerData.client);

      this.OfferService.generateHash(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
          that.offerData.hash = data.hash;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    viewOffer() {
      if (this.offerData.hash) {
        let offer_link = "/view/" + this.offerData.hash;
        window.open(offer_link, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.section_header {
  display: flex;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: 400;
    margin: 0 5px 0 0;
  }
}


.template_name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.offer_statistic {
    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        li {
            margin-bottom: 2px;
            span {
                &:first-of-type {
                    font-weight: 500;
                }
            }
        }
    }
}

.offer_structure {
  &_section {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      flex: auto;
      line-height: 1.1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &_controls {
      align-items: center;
      flex: 0 0 80px;
      width: 80px;
      max-width: 80px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    &:hover {
      background-color: #f3f3f3;
    }
  }
  &_container:not(:last-of-type) {
    border-bottom: 1px #f3f3f3 solid;
    
  }
}


</style>