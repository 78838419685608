<template>
  <page-header>Настройки</page-header>
  <div class="col-12" v-if="serviceAccess">
    <div class="card" v-if="!loading">
        <router-link to="/sections" v-if="userData.group == 'admin'">
            <Button label="Редактировать шаблоны" icon="pi pi-check" class="p-button"/>
        </router-link>
    </div>
    <preloader v-else></preloader>
  </div>
  <preloader v-else></preloader>
</template>

<script>
import AuthService from "../services/AuthService";
export default {
  data() {
    return {
      userData: [],
      userServices: null,
      serviceAccess: false,
    };
  },
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  mounted() {
    this.AuthService.checkAuth(this);
  },
  computed: {
    loading() {
      if (this.userData && this.userServices) {
        return false;
      } else {
        return true;
      }
    },
  },
}
</script>