<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="STARTA.КП" src="https://offer.starta.eco/assets/img/starta_logo.svg" />
      <!-- <span>SAKAI</span> -->
    </router-link>
    <!-- <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
    </button>-->

    <Menubar :model="menuItems"></Menubar>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
      </li>-->
      <li>
        <button class="p-link layout-topbar-button" title="Настройки" @click="followSettings">
          <i class="pi pi-cog"></i>
          <span>Настройки</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button" title="Мой профиль" @click="followProfile">
          <i class="pi pi-user"></i>
          <span>Мой профиль</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button" title="Выйти" @click="logout">
          <i class="pi pi-sign-out"></i>
          <span>Выход</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";

export default {
  data() {
    return {
      menuItems: [
        {
          label: "Список КП",
          icon: "pi pi-fw pi-file",
          to: "/offers",
        },
        {
          label: "Список шаблонов",
          icon: "pi pi-fw pi-th-large",
          to: "/sections",
        },
      ],
    };
  },
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  // mounted() {
  //     this.AuthService.checkAuth(this);
  // },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    logout() {
      this.AuthService.logout();
    },
    followProfile() {
      window.location.href = "https://id.starta.eco/profile";
    },
    followSettings() {
      // window.location.href = "/settings";
      this.$router.push("/settings");
    },
  },
};
</script>

<style lang="scss" scoped>
	::v-deep(.p-menubar) {
		border: none;
		padding: 0;
		.p-menuitem-link {
			background-color: white;
		}
	}
</style>