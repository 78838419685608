export default class AuthService {
    
    login(formData) {
        const that = this;

        return fetch(process.env.VUE_APP_ID_URL + "/jwt/api/login.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                console.log(data);
                if (data.status) {
                    that.setCookie("starta_token", data.jwt, 1);
                }
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    logout() {
        this.setCookie("starta_token", "", 1);
        window.location.href = process.env.VUE_APP_ID_URL;
    }

    validateToken() {
        var token = this.getCookie('starta_token');
        const formData = new FormData();
        formData.append('token', token);

        return fetch(process.env.VUE_APP_ID_URL + "/jwt/api/validate_token.php", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then(function (data) {
                return Promise.resolve(data);
            })
            .catch(function (err) {
                var data = {status: false, message: err}
                return Promise.resolve(data);
            })
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=starta.eco";
        // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' '){
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    noAccessNotify(that) {
        that.serviceAccess = false;
        that.$toast.add({
          severity: "error",
          summary: "Ошибка авторизации",
          detail: "У вас нет доступа к данному сервису.",
          life: 4000,
        });
        setInterval(function () {
           window.location = process.env.VUE_APP_ID_URL;
        }, 1500);
    }

    checkAuth(context) {
        var token = this.getCookie("starta_token");
        if (token) {
            this.validateToken().then((data) => {
                if (data.status) {
                    context.userData = data.data;
                    if (data.data.services) {
                        context.serviceAccess = true;
                        context.userServices = JSON.parse(data.data.services);
                        if (context.userServices.indexOf("offer") !== -1 && data.data.role !== 'client') {
                            context.serviceAccess = true;
                        } else {
                            this.noAccessNotify(context);
                        }
                    } else {
                        this.noAccessNotify(context);
                    }
                } else {
                    this.noAccessNotify(context);
                }
            });
        } else {
            this.noAccessNotify(context);
        }
    }

}