<template>
  <DataTable
    :value="data"
    responsiveLayout="scroll"
    :paginator="true"
    :rows="20"
    sortField="id"
    :sortOrder="-1"
  >
    <template #empty>
        Пока не создано ни одного шаблона
    </template>
    <Column field="id" header="ID" :sortable="true" style="width: 10%;"></Column>
    <Column field="header" header="Шаблон" :sortable="true" style="width: 80%;">
      <template #body="slotProps">
        <div class="template_name">
            <router-link :to="'/sections/' + slotProps.data.id">
                <Avatar :image="'https://offer.starta.eco/templates/' + slotProps.data.image"  class="mr-2" size="xlarge" v-if="slotProps.data.image"/>
                <Avatar image="https://offer.starta.eco/templates/tpl_nophoto.jpg"  class="mr-2" size="xlarge"  v-else/>
            </router-link>
            <div class="temaplte_">
                <router-link :to="'/sections/' + slotProps.data.id">
                    <p class="h5">{{slotProps.data.header}}</p>
                </router-link>
                <p>{{slotProps.data.description}}</p>
            </div>
        </div>
      </template>
    </Column>
    <Column style="width: 10%;">
      <template #body="slotProps">
        <router-link :to="'/sections/' + slotProps.data.id">
          <Button icon="pi pi-pencil" class="p-button-sm p-button-secondary mr-2" />
        </router-link>
        <Button
          icon="pi pi-trash"
          class="p-button-sm p-button-danger"
          @click="confirmDeleteTemplate(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>

  <Dialog
    v-model:visible="deleteTemplateDialog"
    :style="{width: '450px'}"
    header="Подтвердите удаление"
    :modal="true"
  >
    <div class="confirmation-content">
      <span v-if="templateToDelete">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem; float: left;" />
        Вы уверены, что хотите удалить шаблон "{{templateToDelete.header}}"?
      </span>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteTemplateDialog = false"
      />
      <Button
        label="Да, удалить"
        icon="pi pi-check"
        class="p-button-warning"
        @click="deleteTemplate(templateToDelete.id)"
      />
    </template>
  </Dialog>
</template>

<script>
import OfferService from "../../services/OfferService";

export default {
  data() {
    return {
      deleteTemplateDialog: false,
      templateToDelete: null,
    };
  },
  props: ["data"],
  emits: ["refreshTemplates"],
  OfferService: null,
  created() {
    this.OfferService = new OfferService();
  },
  methods: {
    confirmDeleteTemplate(template) {
      this.templateToDelete = template;
      this.deleteTemplateDialog = true;
    },
    deleteTemplate(id) {
      const that = this;
      this.OfferService.deleteTemplate(id).then((data) => {
        if (data.status) {
          this.deleteTemplateDialog = false;
          this.$emit("refreshTemplates");
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.template_name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
</style>