<template>
  <Toast />
  <ConfirmPopup></ConfirmPopup>
  <ScrollTop />
  <div :class="containerClass" @click="onWrapperClick">
    <!-- <AppTopBar @menu-toggle="onMenuToggle"></AppTopBar> -->
    <AppTopBar></AppTopBar>
    <!-- <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick"></AppMenu>
    </div> -->

    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <router-view></router-view>
        </div>
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./components/ui/AppTopbar.vue";
// import AppMenu from "./components/ui/AppMenu.vue";
import AppFooter from "./components/ui/AppFooter.vue";

export default {
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      // menu: [
      //   {
      //     label: "Коммерческие предложения",
      //     icon: "pi pi-fw pi-sitemap",
      //     items: [
      //       {
      //         label: "Список КП",
      //         icon: "pi pi-fw pi-angle-double-right",
      //         to: "/offers",
      //       },
      //       {
      //         label: "Список шаблонов",
      //         icon: "pi pi-fw pi-angle-double-right",
      //         to: "/sections",
      //       },
      //     ],
      //   },
      // ],
    };
  },
  watch: {
    $route(to) {
      this.menuActive = false;
      this.$toast.removeAllGroups();
      document.title = to.meta.title + " | STARTA" || "STARTA";

      var metaRobots = document.createElement('meta');
      metaRobots.name = "robots";
      metaRobots.content = "noindex, nofollow";
      document.getElementsByTagName('head')[0].appendChild(metaRobots);
    },
  },
  components: {
    AppTopBar: AppTopBar,
    // AppMenu: AppMenu,
    AppFooter: AppFooter
  },
  methods: {
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
  },
      computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  // metaInfo: {
  //     title: 'My Example App',
  //     titleTemplate: '%s - Yay!',
  //     htmlAttrs: {
  //       lang: 'en',
  //       amp: true
  //     }
  //   }
};
</script>

<style lang="scss">

  html {
    font-size: 16px !important;
  }

  .access_denied_overlay {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #e3e3e3;
    z-index: 1000;
  }

  .p-avatar img {
    object-fit: cover;
  }

</style>