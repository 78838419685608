<template>
  <div class="grid">
    <div class="col-2">
      <Avatar
        :image="'https://offer.starta.eco/templates/' + template.image"
        class="mr-2 w-full h-full"
        size="xlarge"
        v-if="template.image"
      />
      <Avatar
        image="https://offer.starta.eco/templates/tpl_nophoto.jpg"
        class="mr-2 w-full h-full"
        size="xlarge"
        v-else
      />
    </div>
    <div class="grid col-10">
      <div class="col-4">
        <label for="template_header">
          <span>Заголовок блока</span>
        </label>
        <InputText
          type="text"
          id="template_header"
          @input="translitName(template.header)"
          name="header"
          v-model="template.header"
          required
        />
      </div>
      <div class="col-3">
        <label for="template_name" class="p-disabled">
          <span>Название блока</span>
        </label>
        <InputText
          type="text"
          id="template_name"
          name="name"
          class="p-disabled"
          v-model="template.name"
          disabled
          required
        />
      </div>
      <div class="col-12">
        <label for="template_description">
          <span>Краткое описание</span>
        </label>
        <InputText
          type="text"
          id="template_description"
          name="description"
          v-model="template.description"
          required
        />
      </div>
      <div class="col-12">
        <label for="template_image">
          <span>Изображение (сюда можно загрузить скрин шаблона, чтобы менеджеру было удобнее искать)</span>
        </label>
        <FileUpload
          mode="basic"
          name="image[]"
          :customUpload="true"
          @uploader="imageUploader"
          @select="imageSelected"
          accept="image/*"
          :maxFileSize="2097152"
          :auto="true"
          chooseLabel="Загрузить превью"
          :class="newTpl ? 'p-disabled' : ''"
          :title="newTpl ? 'Можно будет добавить только после сохранения шаблона' : ''"
        />
        {{uploadedImage}}
      </div>
    </div>
    <div class="col-12">
      <label for="template_content">
        <span>Содержимое шаблона</span>
      </label>
      <editor
        api-key="eglpylzpl5vuggnchp04c2din4ratgqevieq9rpscgylsuky"
        v-model="template.content"
        name="template_content"
        :init="{
            height: 500,
            menubar: true,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table code help wordcount',
            ],
            language: 'ru',
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
        }"
      />
    </div>
    <div class="col-12">
      <Button label="Сохранить" @click="saveTemplate"></Button>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import OfferService from "../../services/OfferService";

export default {
  data() {
    return {
      template: this.data,
      uploadedImage: null,
    };
  },
  OfferService: null,
  props: ["data", "newTpl"],
  emits: ["save"],
  created() {
    this.OfferService = new OfferService();
  },
  components: {
    editor: Editor,
  },
  methods: {
    saveTemplate() {
      this.$emit("save", this.template);
    },
    translitName(string) {
      var answer = "";
      var converter = {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "e",
        ж: "zh",
        з: "z",
        и: "i",
        й: "y",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "sch",
        ь: "",
        ы: "y",
        ъ: "",
        э: "e",
        ю: "yu",
        я: "ya",

        А: "A",
        Б: "B",
        В: "V",
        Г: "G",
        Д: "D",
        Е: "E",
        Ё: "E",
        Ж: "Zh",
        З: "Z",
        И: "I",
        Й: "Y",
        К: "K",
        Л: "L",
        М: "M",
        Н: "N",
        О: "O",
        П: "P",
        Р: "R",
        С: "S",
        Т: "T",
        У: "U",
        Ф: "F",
        Х: "H",
        Ц: "C",
        Ч: "Ch",
        Ш: "Sh",
        Щ: "Sch",
        Ь: "",
        Ы: "Y",
        Ъ: "",
        Э: "E",
        Ю: "Yu",
        Я: "Ya",

        ".": "_",
        ",": "_",
        " ": "_",
        ":": "_",
        ";": "_",
        "|": "_",
        "/": "_",
        "\\": "_",
        "\"": "_",
        "'": "_",
        "@": "_",
        "#": "_",
        "$": "_",
        "№": "_",
        "?": "_",
        "*": "_",
        "!": "_",
        "`": "_",
        "~": "_",
        "%": "_",
        "^": "_",
        "&": "_",
        "(": "_",
        ")": "_",
        "-": "_",
        "=": "_",
        "+": "_",
        "[": "_",
        "]": "_",
        "{": "_",
        "}": "_",
      };

      for (var i = 0; i < string.length; ++i) {
        if (converter[string[i]] == undefined) {
          answer += string[i];
        } else {
          answer += converter[string[i]];
        }
      }

      this.template.name = answer.toLowerCase();
    },
    imageUploader(e) {
      var files = e.files;
      // var token = this.AuthService.getCookie("starta_token");

      const that = this;
      const formData = new FormData();
      formData.append("action", "uploadTemplatePreview");
      formData.append("id", this.template.id);
      // formData.append("token", token);
      // formData.append("id", this.userData.id);
      formData.append("files", files[0]);

      this.OfferService.uploadTemplatePreview(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            summary: "Фото обновлено",
            detail: data.message,
            life: 4000,
          });
          if (data.image === "null") {
            that.template.image = null;
          } else {
            that.template.image = data.image;
          }
          e.files = null;
        } else {
          that.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    imageSelected(e) {
      var files = e.files;
      this.uploadedImage = files[0].name;
    }
  },
};
</script>