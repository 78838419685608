<template>
        <Toolbar class="mb-4">
          <template #start>
            <div class="header_buttons_group" v-if="!offer.hash">
              <Button
                label="Сформировать КП"
                icon="pi pi-check-square"
                :disabled="!offer.status"
                class="p-button-success mr-2"
                @click="publicateOffer"
              />
            </div>
            <div class="header_buttons_group" v-else>
              <Button
                label="Просмотр"
                icon="pi pi-eye"
                class="p-button-success mr-2"
                @click="viewOffer"
              />
              <Chip label="КП сфомировано" class="px-3 mr-2" />
              <Button icon="pi pi-link" @click="copyLink" title="Скопировать ссылку для клиента" class="p-button-rounded p-button-text" />
            </div>
          </template>

          <template #end>
            <div class="offer_status mr-4">
              <InputSwitch v-model="status" @change="changeStatus" />
              <span>{{offerStatus}}</span>
            </div>
            <Button type="submit" label="Сохранить"></Button>
          </template>
        </Toolbar>
</template>

<script>
export default {
    data() {
        return {
            status: null
        }
    },
    props: ["offer"],
    emits: ["publicate", "view", "changeStatus"],
    mounted() {
        this.status = this.offer.status;
    },
    computed: {
        offerStatus() {
            if (this.status) {
                return "КП активно";
            } else {
                return "КП выключено";
            }
        }
    },
    methods: {
        publicateOffer() {
            this.$emit('publicate', this.offer.id);
        },
        viewOffer() {
            this.$emit('view', this.offer.id);
        },
        changeStatus() {
            this.$emit('changeStatus', this.status);
        },
        copyLink() {
          let offer_link = "https://offer.starta.eco/view/" + this.offer.hash;
          const that = this;
          // console.log(offer_link);
          this.$copyText(offer_link).then(function (e) {
            that.$toast.add({
              severity: "success",
              detail: 'Ссылка скопирована',
              life: 3000,
            });
            console.log(e.text);
          }, function (e) {
            that.$toast.add({
              severity: "warn",
              detail: 'Не удалось скопировать ссылку',
              life: 3000,
            });
            console.log(e.text);
          })

        }
    }
}
</script>

<style lang="scss" scoped>
.header_buttons_group {
  display: flex;
  align-content: revert;
  align-items: center;
}
.offer_status {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 16px;
  }
}
</style>