<template>
    <div class="loading">
        <img src="@/assets/img/preloader.gif" alt="Загрузка...">
    </div>
</template>

<style lang="scss" scoped>
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: #ffffff;
    }
</style>