<template>
    <page-header>Коммерческие предложения</page-header>
    <div class="col-12" v-if="serviceAccess">
        <div class="card" v-if="!loading">
            <Toolbar class="mb-4">
                <template #start>
                    <Button label="Создать новое КП" icon="pi pi-plus" class="p-button-success mr-2" @click="openNewOfferDialog" />
                </template>
            </Toolbar>
            <offers-list :data="offers" @refreshOffers="refreshOffers()"></offers-list>

            <Dialog v-model:visible="NewOfferDialog" :style="{width: '450px'}" header="Новое коммерческое предложение" :modal="true" class="p-fluid" ref="newOfferForm">

                <div class="field">
                    <label for="title">Название</label>
                    <InputText v-model.trim="newOfferData.title" required="true"/>
                </div>
                <div class="field">
                    <label for="client">Название компании</label>
                    <InputText v-model.trim="newOfferData.client" required="true"/>
                </div>
                <!-- <div class="field">
                    <label for="manager">Менеджер</label>
                    <InputText v-model.trim="newOfferData.manager_id" required="true"/>
                </div> -->

                <template #footer>
                    <Button label="Отмена" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeNewOfferDialog"/>
                    <Button label="Создать" icon="pi pi-check" class="p-button-success" @click="createNewOffer" />
                </template>

            </Dialog>
        </div>
        <preloader v-else></preloader>
    </div>
    <preloader v-else></preloader>
</template>

<script>
import AuthService from '../services/AuthService';
import OfferService from '../services/OfferService';

export default {
    data() {
        return {
            userData: [],
            userServices: null,
            serviceAccess: false,
            
            offers: null,
            NewOfferDialog: false,
            newOfferData: {},
        }
    },
    OfferService: null,
    AuthService: null,
    created() {
        this.AuthService = new AuthService();
        this.OfferService = new OfferService();
    },
    mounted() {
        this.checkAuth();      
    },
    computed: {
        loading() {
            if (this.userData && this.userServices && this.offers) {
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        checkAuth() {
            const that = this;
            var token = this.AuthService.getCookie("starta_token");
            if (token) {
                this.AuthService.validateToken().then((data) => {
                    if (data.status) {
                        that.userData = data.data;
                        if (data.data.services) {
                            that.serviceAccess = true;
                            that.userServices = JSON.parse(data.data.services);
                            if (that.userServices.indexOf("offer") !== -1 && data.data.role !== 'client') {
                                that.serviceAccess = true;
                                // console.log(data.data.role);
                                if (data.data.group == 'admin') {
                                    that.getAllOffers();
                                } else {
                                    that.getOffersByID(that.userData.id);
                                }
                            } else {
                                this.AuthServicenoAccessNotify(that);
                            }
                        } else {
                            this.AuthServicenoAccessNotify(that);
                        }
                    } else {
                        this.AuthServicenoAccessNotify(that);
                    }
                });
            } else {
                this.AuthServicenoAccessNotify(that);
            }
        },
        getAllOffers() {
            const that = this;
            this.OfferService.getAllOffers().then(data => {
                // console.log(data.message);
                if (data.status) {
                    that.offers = data.message;
                    that.loading = false;
                } else {
                    that.$toast.add({
                        severity: "error",
                        detail: data.message,
                        life: 4000,
                    });
                }
            });
        },
        getOffersByID(id) {
            const that = this;
            this.OfferService.getOffersByID(id).then(data => {
                // console.log(data.message);
                if (data.status) {
                    that.offers = data.message;
                    that.loading = false;
                } else {
                    that.$toast.add({
                        severity: "error",
                        detail: data.message,
                        life: 4000,
                    });
                }
            });
        },
        openNewOfferDialog() {
            this.NewOfferDialog = true;
            this.newOfferData = {};
        },
        closeNewOfferDialog() {
            this.NewOfferDialog = false;
            this.newOfferData = {};
        },
        createNewOffer() {
            const that = this;
            if (this.newOfferData.title && this.newOfferData.client) {
                const formData = new FormData();
                formData.append('action', 'createOffer');
                formData.append('title', this.newOfferData.title);
                formData.append('client', this.newOfferData.client);
                formData.append('manager_id', this.userData.id);
                this.OfferService.createOffer(formData).then(data => {
                    if (data.status) {
                        that.NewOfferDialog = false;
                        that.newOfferData = {};
                        that.$toast.add({
                            severity: "success",
                            detail: data.message,
                            life: 4000,
                        });
                        setTimeout(function(){
                            that.$router.push('/offers/' + data.data);
                        }, 2000);
                    } else {
                        that.$toast.add({
                            severity: "error",
                            detail: data.message,
                            life: 4000,
                        });
                    }
                });
            } else {
                that.$toast.add({
                    severity: "warn",
                    detail: 'Необходимо заполнить все поля',
                    life: 4000,
                });
            }
            // console.log(this.newOfferData);
        },
        refreshOffers() {
            const that = this;
            if (this.userData.group == 'admin') {
                that.getAllOffers();
            } else {
                that.getOffersByID(that.userData.id);
            }
        }
    }
}
</script>