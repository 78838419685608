<template>
    <View v-if="$route.path.indexOf('/view') !== -1"/>
    <NotFound v-else-if="$route.path.indexOf('/not_found') !== -1"/>
    <App v-else/>
</template>

<script>
import App from './App';
import View from './views/View.vue';
import NotFound from './views/NotFound.vue';

export default {
    components: {
        App,
        View,
        NotFound
    }
}
</script>